const APP_ROOT_PATH = {
  REACT_APP_ONLINE_TEST_ROOT_PATH: '/app/online-test-v2',
  REACT_APP_COURSE_EXERCISE_ROOT_PATH: '/app/course-exercise',
  REACT_APP_COURSE_EXERCISE_V2_ROOT_PATH: '/app/course-exercise-v2',
  REACT_APP_LMS_ROOT_PATH: '/app/lms-v2',
  REACT_APP_ENTRANCE_TEST_ROOT_PATH: '/app/entrance-test-v2',
  REACT_APP_STUDENT_ENTRANCE_TEST_ROOT_PATH: '/app/entrance-test-student',
  REACT_APP_STUDENT_ENTRANCE_TEST_SAT_ROOT_PATH: '/app/entrance-test-sat-student',
  REACT_APP_STUDENT_ENTRANCE_TEST_TOEIC_ROOT_PATH: '/app/entrance-test-toeic-student',
  REACT_APP_FINAL_TEST_ROOT_PATH: '/app/mid-final-test',
  REACT_APP_FINAL_TEST_STUDENT_ROOT_PATH: '/app/final-test-student',
  REACT_APP_LMS_AI_MOCK_TEST_ROOT_PATH: '/app/lms-ai-mock-test',
  REACT_APP_LMS_COURSE_MANAGEMENT_ROOT_PATH: '/app/lms-course-management',
  REACT_APP_LMS_GRAMMAR_ROOT_PATH: '/app/lms-grammar',
  REACT_APP_LMS_TOEIC_ROOT_PATH: '/app/lms-toeic',
  REACT_APP_LMS_STUDENT_ROOT_PATH: '',
  REACT_APP_LMS_STUDENT_ADMIN_ORIGIN: 'https://superlms-int.dolenglish.vn',
  REACT_APP_FINAL_TEST_ORIGIN: 'https://student-int.dolenglish.vn',
  REACT_APP_LMS_ADMIN_ORIGIN: 'https://student-int.dolenglish.vn',
  REACT_APP_LMS_COURSE_MANAGEMENT_ADMIN_ORIGIN: 'https://student-int.dolenglish.vn',
  REACT_APP_LMS_TEACHER_ROOT_PATH: 'https://teacher-int.dolenglish.vn',
  REACT_APP_LMS_ECCS_ROOT_PATH: 'https://cs-int.dolenglish.vn',
  REACT_APP_LMS_TEACHER_FINAL_TEST_ROOT_PATH: 'https://teacher-int.dolenglish.vn/final-test',
  REACT_APP_STUDENT_FINAL_TEST_TOEIC_ROOT_PATH: '/app/final-test-toeic-student',
  REACT_APP_STUDENT_FINAL_TEST_SAT_ROOT_PATH: '/app/final-test-sat-student',
};
const REDIRECT_URL = {
  // Online test
  REACT_APP_ONLINE_TEST_AUTH_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/online-test-v2/callback',
  REACT_APP_ONLINE_TEST_AUTH_LOGOUT_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/online-test-v2/login',

  // LMS
  REACT_APP_LMS_AUTH_REDIRECT_URL: 'https://student-int.dolenglish.vn/app/lms-v2/callback',
  REACT_APP_LMS_AUTH_LOGOUT_REDIRECT_URL: 'https://student-int.dolenglish.vn/app/lms-v2/login',

  // LMS Student
  REACT_APP_LMS_STUDENT_AUTH_REDIRECT_URL: 'https://superlms-int.dolenglish.vn/callback',
  REACT_APP_LMS_STUDENT_AUTH_LOGOUT_REDIRECT_URL: 'https://superlms-int.dolenglish.vn/login',

  // Entrance test
  REACT_APP_ENTRANCE_TEST_AUTH_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/entrance-test-v2/callback',
  REACT_APP_ENTRANCE_TEST_AUTH_LOGOUT_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/entrance-test-v2/login',

  // Entrance test Student
  REACT_APP_ENTRANCE_TEST_STUDENT_AUTH_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/entrance-test-student/callback',
  REACT_APP_ENTRANCE_TEST_STUDENT_AUTH_LOGOUT_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/entrance-test-student/login',

  // Entrance test SAT Student
  REACT_APP_ENTRANCE_TEST_SAT_STUDENT_AUTH_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/entrance-test-student/callback',
  REACT_APP_ENTRANCE_TEST_SAT_STUDENT_AUTH_LOGOUT_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/entrance-test-student/login',

  // tuhocielts.dolenglish.vn
  REACT_APP_AUTH_DOL_VN_REDIRECT_URL: 'https://tuhoc-int.dolenglish.vn',
  REACT_APP_AUTH_DOL_VN_IN_APP_BROWSER_REDIRECT_URL: 'https://tuhoc-int.dolenglish.vn/callback',
  REACT_APP_AUTH_DOL_VN_LOGOUT_REDIRECT_URL: 'https://tuhoc-int.dolenglish.vn',

  // grammar.dolenglish.vn
  REACT_APP_AUTH_GRAMMAR_REDIRECT_URL: 'http://grammar-int.dolenglish.vn',
  REACT_APP_AUTH_GRAMMAR_LOGOUT_REDIRECT_URL: 'http://grammar-int.dolenglish.vn',
  REACT_APP_AUTH_GRAMMAR_IN_APP_BROWSER_REDIRECT_URL: 'http://grammar-int.dolenglish.vn/callback',

  // Final test
  REACT_APP_FINAL_TEST_AUTH_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/mid-final-test/callback',
  REACT_APP_FINAL_TEST_AUTH_LOGOUT_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/mid-final-test/login',

  // Final test student
  REACT_APP_FINAL_TEST_STUDENT_AUTH_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/final-test-student/callback',
  REACT_APP_FINAL_TEST_STUDENT_AUTH_LOGOUT_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/final-test-student/login',

  // Exercise V2 Student
  REACT_APP_EXERCISE_V2_STUDENT_AUTH_REDIRECT_URL:
    'https://superlms-int.dolenglish.vn/app/course-exercise-v2/callback',
  REACT_APP_EXERCISE_V2_STUDENT_AUTH_LOGOUT_REDIRECT_URL:
    'https://superlms-int.dolenglish.vn/app/course-exercise-v2/login',

  // Lms AI mock test
  REACT_APP_LMS_AI_MOCK_TEST_AUTH_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/lms-ai-mock-test/callback',
  REACT_APP_LMS_AI_MOCK_TEST_AUTH_LOGOUT_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/lms-ai-mock-test/login',

  // Lms Course management
  REACT_APP_LMS_COURSE_MANAGEMENT_AUTH_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/lms-course-management/callback',
  REACT_APP_LMS_COURSE_MANAGEMENT_AUTH_LOGOUT_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/lms-course-management/login',

  // Lms Teacher
  REACT_APP_LMS_TEACHER_AUTH_REDIRECT_URL: 'https://teacher-int.dolenglish.vn/callback',
  REACT_APP_LMS_TEACHER_AUTH_LOGOUT_REDIRECT_URL: 'https://teacher-int.dolenglish.vn',
  REACT_APP_LMS_TEACHER_AUTH_IN_APP_BROWSER_REDIRECT_URL:
    'https://teacher-int.dolenglish.vn/callback',

  // Lms Toeic
  REACT_APP_LMS_TOEIC_AUTH_REDIRECT_URL: 'https://student-int.dolenglish.vn/app/lms-toeic/callback',
  REACT_APP_LMS_TOEIC_AUTH_LOGOUT_REDIRECT_URL:
    'https://student-int.dolenglish.vn/app/lms-toeic/login',

  // Lms eccs
  REACT_APP_AUTH_ECCS_REDIRECT_URL: 'https://cs-int.dolenglish.vn',
  REACT_APP_LMS_ECCS_AUTH_REDIRECT_URL: 'https://cs-int.dolenglish.vn/callback',
  REACT_APP_LMS_ECCS_AUTH_LOGOUT_REDIRECT_URL: 'https://cs-int.dolenglish.vn/',
  REACT_APP_AUTH_LMS_ECCS_DOMAIN: 'cs-int.dolenglish.vn',
  REACT_APP_LMS_ECCS_AUTH_IN_APP_BROWSER_REDIRECT_URL: 'https://cs-int.dolenglish.vn/callback',
};

const AUTH_INFO = {
  REACT_APP_AUTH_DOMAIN: 'student-int.dolenglish.vn',
  REACT_APP_AUTH_DOL_VN_DOMAIN: 'tuhoc-int.dolenglish.vn',
  REACT_APP_AUTH_LMS_STUDENT_DOMAIN: 'superlms-int.dolenglish.vn',
  REACT_APP_AUTH_CLIENT_ID: 'AIzaSyDu06M2ZdguLIvudPDrrwfFPBLjVQPRmFg',
  REACT_APP_AUTH_REAL_TIME_DB_URL:
    'https://pronun-default-rtdb.asia-southeast1.firebasedatabase.app',
  REACT_APP_AUTH_PROJECT_ID: 'pronun',

  REACT_AUTH_LOCAL_STORAGE_KEY: 'dolonline.auth',
  REACT_AUTH_PHONE_VERIFIED_LOCAL_STORAGE_KEY: 'auth.phone.verified',
  REACT_AUTH_CURRENT_USER_INFO: 'auth.currentuser.info',
  REACT_AUTH_CURRENT_USER_ID_TOKEN: 'auth.currentuser.id_token',
  REACT_AUTH_CURRENT_USER_CUSTOM_CLAIM: 'auth.currentuser.custom_claim',
  REACT_APP_AUTH_GRAMMAR_DOMAIN: 'grammar-int.dolenglish.vn',
  REACT_APP_AUTH_TEACHER_DOMAIN: 'teacher-int.dolenglish.vn',
  REACT_APP_AUTH_LMS_TEACHER_DOMAIN: 'teacher-int.dolenglish.vn',
};

const API_HOSTNAME = {
  // Main api
  REACT_APP_ONLINE_TEST_API_HOSTNAME: 'https://int-api.dolenglish.vn/online-test-v2/api',
  REACT_APP_ONLINE_TEST_CHINA_API_HOSTNAME:
    'https://int-api.dolenglish.vn/online-test-v2-china/api',
  REACT_APP_ONLINE_TEST_GLOBAL_API_HOSTNAME:
    'https://int-api.dolenglish.vn/online-test-v2-global/api',
  REACT_APP_UNIVERSITY_API_HOSTNAME: '',
  REACT_APP_PAGE_MANAGEMENT_API_HOSTNAME: 'https://int-api.dolenglish.vn/page-management/api',
  REACT_APP_PAGE_MANAGEMENT_CHINA_API_HOSTNAME:
    'https://int-api.dolenglish.vn/page-management-china/api',
  REACT_APP_PAGE_MANAGEMENT_GLOBAL_API_HOSTNAME:
    'https://int-api.dolenglish.vn/page-management-global/api',
  REACT_APP_EXERCISE_API_HOST_NAME: 'https://int-api.dolenglish.vn/exercise/api',
  REACT_APP_EXERCISE_V2_API_HOST_NAME: 'https://int-api.dolenglish.vn/exercise-v2/api',
  REACT_APP_AI_MOCK_TEST_API_HOST_NAME: 'https://int-api.dolenglish.vn/mock-test/api',
  REACT_APP_GRAMMAR_API_HOSTNAME: 'https://int-api.dolenglish.vn/grammar/api',
  REACT_APP_K12_API_HOSTNAME: 'https://int-api.dolenglish.vn/k12/api',
  REACT_APP_K10_API_HOSTNAME: 'https://int-api.dolenglish.vn/k10/api',
  REACT_APP_PUBLIC_K12_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/k12/api',
  REACT_APP_PUBLIC_K10_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/k10/api',
  REACT_APP_HSA_API_HOSTNAME: 'https://int-api.dolenglish.vn/hsa/api',
  REACT_APP_PUBLIC_HSA_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/hsa/api',
  REACT_APP_TOEIC_API_HOSTNAME: 'https://int-api.dolenglish.vn/toeic/api',
  REACT_APP_TOEIC_PUBLIC_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/toeic/api',
  REACT_APP_VOCAB_API_HOSTNAME: 'https://int-api.dolenglish.vn/vocab-v2/api',
  REACT_APP_DICTATION_API_HOSTNAME: 'https://int-api.dolenglish.vn/dictation/api',
  REACT_PUBLIC_SERVICE_API_HOSTNAME: 'https://int-api.dolenglish.vn/public',
  REACT_APP_ROADMAP_API_HOST_NAME: 'https://int-api.dolenglish.vn/roadmap/api',
  REACT_APP_MEMORIES_API_HOST_NAME: 'https://int-api.dolenglish.vn/memories/api',
  REACT_GO_USER_API_HOSTNAME: 'https://int-api.dolenglish.vn/go-user/api',
  REACT_APP_DOCUMENT_HISTORY_API_HOSTNAME: 'https://int-api.dolenglish.vn/document-history/api',
  REACT_APP_GOTENBERG_API_HOSTNAME: 'https://int-api.dolenglish.vn/gotenberg',

  REACT_APP_TOEIC_ASSIGNMENT_API_HOSTNAME: 'https://int-api.dolenglish.vn/toeic-assignment/api',
  REACT_APP_ENGLISH_UTILS_API_HOSTNAME: 'https://int-api.dolenglish.vn/english-utils/api',

  // public v2
  REACT_PUBLIC_PAGE_MANAGEMENT_SERVICE_API_HOSTNAME:
    'https://int-api.dolenglish.vn/public/page-management/api',
  REACT_PUBLIC_SEARCH_TRANSFORM_SERVICE_API_HOSTNAME:
    'https://int-api.dolenglish.vn/public/search-transform/api',
  REACT_PUBLIC_TTS_SERVICE_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/tts/api',
  REACT_PUBLIC_ONLINE_TEST_V2_SERVICE_API_HOSTNAME:
    'https://int-api.dolenglish.vn/public/online-test-v2/api',
  REACT_PUBLIC_MEMBERSHIP_SERVICE_API_HOSTNAME:
    'https://int-api.dolenglish.vn/public/membership/api',
  REACT_PUBLIC_K12_SERVICE_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/k12/api',
  REACT_PUBLIC_USER_SERVICE_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/user/api',
  REACT_PUBLIC_ENTRANCE_TEST_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/entrance-test/api',
  REACT_PUBLIC_MID_FINAL_TEST_API_HOSTNAME:
    'https://int-api.dolenglish.vn/public/mid-final-test/api',
  REACT_PUBLIC_SAT_ENTRANCE_FINAL_API_HOSTNAME:
    'https://int-api.dolenglish.vn/public/sat-entrance-final/api',
  REACT_PUBLIC_COURSE_APP_SHEET_API_HOSTNAME:
    'https://int-api.dolenglish.vn/public/course-app-sheet/api',
  REACT_PUBLIC_OFFLINE_COURSE_API_HOSTNAME:
    'https://int-api.dolenglish.vn/public/offline-course-management/api',
  REACT_PUBLIC_SEO_REDIRECT_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/seo-redirect/api',
  REACT_PUBLIC_GRAMMAR_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/grammar/api',
  REACT_PUBLIC_K12_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/k12/api',
  REACT_PUBLIC_TOEIC_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/toeic/api',
  REACT_PUBLIC_GO_USER_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/go-user/api',
  REACT_PUBLIC_ASSIGNMENT_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/assignment/api',

  REACT_PUBLIC_APP_AI_MOCK_TEST_API_HOST_NAME: 'https://int-api.dolenglish.vn/public/mock-test/api',
  REACT_PUBLIC_APP_DICTATION_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/dictation/api',
  REACT_PUBLIC_APP_EXERCISE_V2_API_HOST_NAME:
    'https://int-api.dolenglish.vn/public/exercise-v2/api',
  REACT_PUBLIC_APP_VOCAB_API_HOSTNAME: 'https://int-api.dolenglish.vn/public/vocab-v2/api',
  REACT_PUBLIC_APP_MATERIAL_API_HOST_NAME: 'https://int-api.dolenglish.vn/public/material/api',

  // App api host name
  REACT_APP_LANDING_API_HOSTNAME: 'https://tuhoc-int.dolenglish.vn/api',
  REACT_APP_DOL_CORP_WEBSITE_API_HOST_NAME: 'https://corp-int.dolenglish.vn/api',

  // K12
  REACT_APP_K12_SERVICE_API_HOSTNAME: 'https://int-api.dolenglish.vn/k12/api',
  REACT_APP_K12_RESOURCE_MNG_API_HOSTNAME: 'https://int-api.dolenglish.vn/k12-resource-mgmt/api',

  // Other service
  REACT_APP_SEARCH_ENGINE_API_HOSTNAME: 'https://int-api.dolenglish.vn/search-transform',
  REACT_APP_USER_SERVICE_API_HOSTNAME: 'https://int-api.dolenglish.vn/user',
  REACT_APP_KAFKA_REST_API_HOSTNAME: 'https://kafka-rest.int.dolenglish.vn',
  REACT_APP_ACTIVITY_API_HOSTNAME: 'https://int-api.dolenglish.vn/activity/api',
  REACT_S3_SERVICE_API_HOSTNAME: 'https://int-api.dolenglish.vn/s3-service-v2/api',
  REACT_VERIFY_APP_API_HOSTNAME: 'https://int-api.dolenglish.vn/verify-token/api',
  REACT_APP_AUTHORIZATION_API_HOSTNAME: 'https://api-int.dolenglish.vn/authorization/api',
  REACT_APP_CMS_API_HOSTNAME: 'https://int-api.dolenglish.vn/cms',
  REACT_APP_CMS_DIRECTUS_API_HOSTNAME: 'https://int-api.dolenglish.vn/directus',
  REACT_APP_MEMBERSHIP_DOMAIN_NAME: 'https://int-api.dolenglish.vn/membership/api',
  REACT_APP_COGNITIVE_SERVICE_API_HOST_NAME: 'https://int-api.dolenglish.vn/tts/api',
  REACT_APP_ASSIGNMENT_API_HOST_NAME: 'https://int-api.dolenglish.vn/assignment/api',
  REACT_APP_COURSE_MANAGEMENT_API_HOSTNAME:
    'https://int-api.dolenglish.vn/offline-course-management/api',
  REACT_APP_ENTRANCE_TEST_API_HOSTNAME: 'https://int-api.dolenglish.vn/entrance-test/api',
  REACT_APP_SYLLABUS_MANAGEMENT_API_HOSTNAME: 'https://int-api.dolenglish.vn/syllabus',
  REACT_APP_MATERIAL_API_HOST_NAME: 'https://int-api.dolenglish.vn/material/api',

  REACT_APP_MARKING_FORM_API_HOSTNAME: 'https://int-api.dolenglish.vn/marking-form/api',
  REACT_APP_MID_FINAL_TEST_API_HOSTNAME: 'https://int-api.dolenglish.vn/mid-final-test/api',
  REACT_APP_COURSE_APP_SHEET_API_HOSTNAME: 'https://int-api.dolenglish.vn/course-app-sheet/api',
  REACT_APP_SAT_TEST_API_HOSTNAME: 'https://int-api.dolenglish.vn/sat/api',
  REACT_APP_SAT_TEST_GLOBAL_API_HOSTNAME: 'https://int-api.dolenglish.vn/sat-global/api',
  REACT_APP_ENTRANCE_SAT_TEST_API_HOSTNAME: 'https://int-api.dolenglish.vn/entrance-sat/api',
  REACT_APP_SEARCH_SERVICE_API_HOSTNAME: 'https://int-api.dolenglish.vn/search-transform/api',
  // M2M
  REACT_APP_M2M_ONLINE_TEST_API_HOSTNAME: 'http://online-test-v2-api-int-dol-api/api',
  REACT_APP_M2M_PAGE_MANAGEMENT_API_HOSTNAME: 'http://page-management-api-int-dol-api/api',
  REACT_APP_M2M_DOL_CORP_WEBSITE_API_HOST_NAME: 'http://corp-website-int-dol-web/api',
  REACT_APP_M2M_ENTRANCE_TEST_API_HOSTNAME: 'http://entrance-test-api-int-dol-api/api',
  REACT_APP_M2M_MID_FINAL_TEST_API_HOSTNAME: 'http://mid-final-test-api-int-dol-api/api',
  REACT_APP_M2M_COURSE_APP_SHEET_API_HOSTNAME: 'http://course-app-sheet-api-int-dol-api/api',
  REACT_APP_M2M_SEARCH_SERVICE_API_HOSTNAME: 'http://search-transform-int-dol-api/api',
  REACT_APP_M2M_OFFLINE_COURSE_API_HOSTNAME: 'http://offline-course-management-api-int-dol-api/api',

  // QUIZ
  REACT_APP_QUIZ_TEST_API_HOSTNAME: 'https://int-api.dolenglish.vn/quiz-test/api',

  // Other services
  REACT_TRACK_USER_LOCATION_HOST_NAME:
    'https://asia-southeast1-pronun.cloudfunctions.net/user-location',
  REACT_GOOGLE_CLOUD_AUDIO_SERVICE: 'https://audio-service-2d746c6kia-as.a.run.app/api',
  REACT_APP_WORD_CHECKER_API_HOSTNAME: 'https://int-api.dolenglish.vn/word-checker/api',
  REACT_APP_WRITING_ASSESSMENT_API_HOSTNAME: 'https://int-api.dolenglish.vn/writing-assessment/api',
};

const EXTERNAL = {
  REACT_APP_SUBMIT_LEAD_API:
    'https://n2twv8qh8c.execute-api.ap-southeast-1.amazonaws.com/student-registration',
  REACT_APP_TEXT_TO_SPEECH_API: 'https://wkftq343jbobj.vcdn.cloud/prod/text-to-speech',

  // CDN
  CDN_GET_PRIVATE_RESOURCE: 'https://gqefcpylonobj.vcdn.cloud', // dâm, work around chỉ nên sử dụng cho landing page - contact Duy, Cuong for more info
  CDN_GET_PUBLIC_RESOURCE: 'https://cbq1uz9dtwobj.vcdn.cloud',
  CDN_GET_PREVIEW_AUDIO: 'https://y0v7w5zgtbobj.vcdn.cloud', // VNG cnd for view de
  FPT_CDN_GET_RESOURCE: 'https://s3-sgn10.fptcloud.com',

  // AWS
  AWS_APP_SYNC_HOST_NAME:
    'https://uvj4scxxobanbii5qb6p6nyq2q.appsync-api.ap-southeast-1.amazonaws.com/graphql',
  AWS_APP_SYNC_API_KEY: 'da2-woup2rztwbdnlfgqjtvjii5azm',

  // Facebook
  FACEBOOK_APP_ID: '827030637985687',

  // Customer IO
  REACT_APP_CUSTOMER_IO_SITE_ID: '184db37492124e57be3f',

  // Hubspot DOL
  HUBSPOT_API_KEY: 'c4ea6d41-2128-45f5-b856-5c20c4991a63',
  HUBSPOT_PORTAL_ID: '21448405',
  HUBSPOT_FORM_ID: '50c265c6-1026-4b52-8008-014f2effedbe',

  // Hubspot UEF
  HUBSPOT_UEF_PORTAL_ID: '47430100',

  REACT_APP_SEO_REDIRECT_API_HOSTNAME: 'https://int-api.dolenglish.vn/seo-redirect/api',
  REACT_APP_VIDEO_SERVICE_API_HOSTNAME: 'https://int-api.dolenglish.vn/video/v1/api',
  DIRECTUS_API_HOST_NAME: 'https://directus-int.dolenglish.vn',

  // Junior sheet
  REACT_APP_JUNIOR_SHEET:
    'https://docs.google.com/spreadsheets/d/1HVG8VnLf98vsroQJapTDyR2O0v15u3o33rAy1GUU9H4/edit?gid=0#gid=0',
};

const SEO_REDIRECT = {
  SEO_REDIRECT_DOL_ENGLISH: 'https://corp-int.dolenglish.vn',
  SEO_REDIRECT_TU_HOC_IELTS: 'https://tuhoc-int.dolenglish.vn',
  SEO_REDIRECT_TU_DIEN: 'https://tudien-int.dolenglish.vn',
  SEO_REDIRECT_GRAMMAR: 'https://grammar-int.dolenglish.vn',
};

export const environment = {
  ...APP_ROOT_PATH,
  ...REDIRECT_URL,
  ...AUTH_INFO,
  ...API_HOSTNAME,
  ...EXTERNAL,
  ...SEO_REDIRECT,

  production: false,
  LANDING_GLOBAL_CACHE_TTL: 10, // 10 seconds cache
  REACT_APP_ENV: '',
  NEXT_PUBLIC_SENTRY_ENV: 'INTEGRATION',
  INTERNAL_PATH: 'internal',

  REACT_USER_SEARCH_LOCAL_STORAGE_KEY: 'dolonline.search',
  SEO_PREFIX: 'https://tuhoc-int.dolenglish.vn/',
  REACT_APP_DOL_CORP_WEBSITE_HOST_NAME: 'https://corp-int.dolenglish.vn',
  REACT_APP_DOL_DICTIONARY_WEBSITE_HOST_NAME: 'https://tudien-int.dolenglish.vn',
  REACT_APP_DOL_GRAMMAR_WEBSITE_HOST_NAME: 'https://grammar-int.dolenglish.vn',
  REACT_APP_DOL_TEACHER_WEBSITE_HOST_NAME: 'https://teacher-int.dolenglish.vn',
  REACT_APP_DOL_SUPER_LMS_HOST_NAME: 'https://superlms-int.dolenglish.vn',
  REACT_APP_DOL_STUDENT_HOST_NAME: 'https://student-int.dolenglish.vn',

  GRAPH_CMS_CDN: 'https://asset.dolenglish.vn',
  REACT_APP_REFERRAL_DOMAIN_NAME: 'https://invite-tuhoc-int.dolenglish.vn',
  REACT_APP_ENGLISH_GROUP_LINK: '',
  GIFT_CODE_ROUTER: 'https://student-int.dolenglish.vn/',
  HUBSPOT_HOST_NAME: 'https://app.hubspot.com',
  LANDING_GRAMMAR_FULL_URL: 'https://grammar-int.dolenglish.vn',
};
